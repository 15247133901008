.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
body {
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}

input {
  width: 30%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: rgb(197, 222, 242);
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card {
  margin-bottom: 10px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin: 20px 0;
}

.search-input {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.clear-button {
  margin-left: 0px;
  width: 10%;
  padding: 10px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  transition: none; /* 禁用过渡效果 */
  -webkit-tap-highlight-color: transparent; /* 禁用点击高亮效果 */
  border: none;
  border-radius: 5px;
  bottom: 20px;
}

.clear-button:focus {
  outline: none;
}

.spacer {
  height: 20px;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.scroll-to-top-button:hover {
  background-color: #0056b3;
}